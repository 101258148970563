<template>
  <v-row fill-height align="center" justify="center" no-gutters>
    <v-col cols="12" align="center" @click="expand = false">
      <v-card class="pa-4 containerBorder" width="95%" flat>
        <v-data-table hide-default-footer :items-per-page="-1" :height="viewBoxHeight" :headers="header"
          :items="getJudgeLocations" :loading="loading" loading-text="Fetching latest data. Please wait..."
          fixed-header>
          <template v-slot:item.judgeName="{ item }">
            <h3>{{ item.judgeName }}</h3>
          </template>
          <template v-slot:item.courtroom="{ item }">
            <v-btn @click="handleCourtLocation(item.courtroom)" width="100%" elevation="0" color="primary" class="my-4">
              <v-icon left>mdi-gesture-tap</v-icon>
              {{ item.courtroom }}
            </v-btn>
          </template>
          <template v-slot:item.courtroomFloor="{ item }">
            <h3>{{ item.courtroomFloor }}</h3>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-text class="ma-0 pb-0 my-4">
          <span class="mr-5 text-h6">Don't know Judge assigned to your case?</span>
          <v-btn color="primary" class="ml-5" @click="handleCourtLocation(getDefaultLocation)"><v-icon
              left>mdi-gesture-tap</v-icon>{{
                getDefaultLocation
              }}</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import moment from "moment";
import { mapGetters } from "vuex/dist/vuex.common.js";
export default {
  name: "todayshearing",
  data() {
    return {
      searchKeyword: null,
      viewBoxHeight: 650,
      expand: false,
      loading: true,
      header: [
        {
          text: "Judge Name",
          align: "start",
          value: "judgeName",
          width: "750",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Court Room Location",
          align: "center",
          value: "courtroom",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Court Room Floor",
          align: "center",
          value: "courtroomFloor",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getKioskProfile", "getSessionId", "getJudgeLocations", "getDefaultLocation"]),
  },
  components: {
    SimpleKeyboard
  },
  watch: {
    expand(n, o) {
      if (n) {
        this.viewBoxHeight = 370
      } else {
        this.viewBoxHeight = 600;
      }
    }
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    onInputChange(input) {
      this.searchKeyword = input.target.value;
    },
    handleCourtLocation(location) {
      var mapLocation = this.$i18n.t("maps").filter(map => map.mapName === location)[0]
      console.log('Hearing Room location: ', mapLocation)
      if (mapLocation === undefined) {
        if(location.toLowerCase() === "mason historical courthouse"){
          this.$store.dispatch('avatarSpeak', "The Mason Historical Courthouse (55th District Court) is at 700 Buhl St., Mason, MI 48854.")
        }else{
          this.$store.dispatch('avatarSpeak', "The Circuit Court Annex at 426 S. Walnut Street.")
        }
      } else {
        this.$store.dispatch('openImageViewer', {
          name: mapLocation.mapName,
          url: mapLocation.mapImage
        })
        this.$store.dispatch('avatarSpeak', mapLocation.speech)
        this.$store.dispatch('addSessionDatatoStrapi', {
          applicationId: this.getKioskProfile.data().applicationId,
          applicationSessionId: this.getSessionId,
          timeStamp: moment().toISOString(),
          actionType: 'Touch',
          sessionPayload: {
            module: "Frequently Asked Question",
            action: "View Map",
            response: mapLocation.mapName,
          }
        })
      }

    },

    openHearingSearchPortal() {
      window.open("https://www.courts.mo.gov/cnet/nameSearch.do?newSearch=Y")
    }
  },
  mounted() {
    this.loading = false
    // setTimeout(() => {
    //   this.$store.dispatch("fetchHearingInfo").then(response => {
    //     if (response) {
    //       this.loading = false
    //     }
    //   })
    // }, 1500)

  },
  filters: {
    formatStringDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LL");
    },
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
  },
};
</script>

<style></style>

<i18n>
{
  "en": {
      "searchcaseno": "Search by case number",
      "searchcasename": "Search by name",
      "firstnamelabel": "First Name",
      "middlenamelabel": "Middle Name",
      "lastnamelabel": "Last Name",
      "casenolabel": "Case Number",
      "casenobutton": "Case Number Search",
      "searchnamebutton": "Search by Name",
      "casenoplaceholder": "Tap to enter case number. E.g. D-xx-xxx-xxxx-xxxxx",
      "placeHolder": "Tap to search by name"
    },
  "es":{
      "searchcaseno": "Buscar por número de caso",
      "searchcasename": "Buscar por nombre",
      "firstnamelabel": "Primer nombre",
      "middlenamelabel": "Segundo nombre",
      "lastnamelabel": "Apellido",
      "casenolabel": "Número de caso",
      "casenobutton": "Número de caso",
      "searchnamebutton": "Buscar por nombre",
      "casenoplaceholder": "Toque para ingresar el número de caso. P.ej. D-xx-xxx-xxxx-xxxxx"
    }
}
</i18n>
