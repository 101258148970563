<template>
  <v-row class="mt-5" no-gutters align="center" justify="center">
      <v-col cols="6">
          <v-card flat>
              <v-card-text class="d-flex justify-center pa-0">
                  <v-text-field placeholder="Enter a keyword to search for FAQ" class="text-h5" hide-details outlined
                      height="55px" rounded v-model="searchKeyword" @click="expand = !expand;"
                      :append-icon="searchBarIcon" clear-icon="mdi-close-circle"
                      @click:append="searchKeyword = ''; expand = false">
                  </v-text-field>
                  <v-icon size="35" class="ml-2" v-if="!expand"
                      @click="expand = !expand">mdi-keyboard-outline</v-icon>
                  <v-icon size="35" class="ml-2" v-else @click="expand = !expand">mdi-keyboard-off-outline</v-icon>
              </v-card-text>
              <v-expand-transition>
                  <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
                      <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
                      </SimpleKeyboard>
                  </v-card-text>
              </v-expand-transition>
          </v-card>
      </v-col>
      <v-col cols="12">
          <v-row no-gutters>
              <v-col cols="3" class="mt-5" align="center" v-if="!expand && searchKeyword === ''">
                  <v-card width="95%" class="containerBorder" :height="cardHeight">
                      <v-card-title class="justify-center primary--text">{{ $t('categoryTitle') }}</v-card-title>
                      <v-divider class="mx-3"></v-divider>
                      <v-list class="overflow-y-auto" :height="cardHeight - 80">
                          <v-list-item v-for="(cat, i) in $t('faqCategory')" :key="i">
                              <v-list-item-content>
                                  <v-btn height="45"
                                      @click="$store.commit('setSelectedFaqCat', { name: cat.categoryName, type: cat.displayType }); panel = 0"
                                      v-bind:color="getSelectedFaqCat.name.toLowerCase() === cat.categoryName.toLowerCase() ? 'secondary' : 'primary'">{{
                                          cat.categoryName
                                      }}</v-btn>
                              </v-list-item-content>
                          </v-list-item>
                      </v-list>
                  </v-card>
              </v-col>
              <v-col :cols="colLenght" class="mt-5" align="center">
                  <v-card width="95%" class="containerBorder overflow-y-auto" :height="cardHeight">
                      <v-expansion-panels focusable dark class="px-5 py-3" v-if="!expand && searchKeyword === ''"
                          popout v-model="panel">
                          <v-expansion-panel v-for="(item, i) in filteredFaqs()" :key="i" class="mt-2">
                              <v-expansion-panel-header color="primary" class="white--text text-h6"
                                  @click="clickedFaq(item)">
                                  <template v-slot:actions>
                                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                                  </template>
                                  {{ item.question }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                                  <v-row class="fill-height" no-gutters v-if="!item.showMap">
                                      <v-col cols="12">
                                          <span v-html="item.answer"></span>
                                      </v-col>
                                  </v-row>
                                  <v-row class="fill-height" no-gutters v-else>
                                      <v-col cols="9">
                                          <span v-html="item.answer"></span>
                                      </v-col>
                                      <v-col cols="3" align="center">
                                          <v-btn tile color="secondary" @click="showMap(item.mapName)" class="my-3">
                                              <v-icon left dark medium> mdi-map-marker-radius </v-icon>
                                              {{ item.mapName | trimLength }}
                                          </v-btn>
                                      </v-col>
                                  </v-row>
                              </v-expansion-panel-content>
                          </v-expansion-panel>
                      </v-expansion-panels>
                      <v-expansion-panels focusable dark class="px-3" popout v-else v-model="panel">
                          <v-expansion-panel v-for="(item, i) in matchingFaqs" :key="i" class="mt-2">
                              <v-expansion-panel-header color="primary" class="white--text text-h6"
                                  @click="clickedFaq(item)">
                                  <template v-slot:actions>
                                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                                  </template>
                                  {{ item.question }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                                  <v-row class="fill-height" no-gutters v-if="!item.showMap">
                                      <v-col cols="12">
                                          <span v-html="item.answer"></span>
                                      </v-col>
                                  </v-row>
                                  <v-row class="fill-height" no-gutters v-else>
                                      <v-col cols="9">
                                          <span v-html="item.answer"></span>
                                      </v-col>
                                      <v-col cols="3" align="center">
                                          <v-btn tile color="secondary" @click="showMap(item.mapName)" class="my-3">
                                              <v-icon left dark medium> mdi-map-marker-radius </v-icon>
                                              {{ item.mapName | trimLength }}
                                          </v-btn>
                                      </v-col>
                                  </v-row>
                              </v-expansion-panel-content>
                          </v-expansion-panel>
                      </v-expansion-panels>
                  </v-card>
              </v-col>
          </v-row>
      </v-col>
  </v-row>
</template>

<script>

import { mapGetters } from 'vuex';
import SimpleKeyboard from "@/components/SimpleKeyboard";


export default {
  name: 'faqs',
  components: {
      SimpleKeyboard,
  },
  data() {
      return {
          tab: 0,
          input: '',
          searchKeyword: '',
          searchBarIcon: 'mdi-magnify',
          expand: false,
          cardHeight: 690,
          displayType: 'expansionPanel',
          colLenght: '9',
          panel: 0
      }
  },
  computed: {
      ...mapGetters(['getQnaKeyword', 'getSelectedFaqCat', "getKioskProfile", "getSessionId"]),

      // Returns matching FAQs based on searched keyword
      matchingFaqs() {
          return this.$i18n.t("faqs").filter(faq => faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()))
      },
      faqCategory() {
          return this.$i18n
              .t("faqCategory").map(category => category.categoryName)
      }
  },
  watch: {
      expand(newVal) {
          if (newVal) {
              this.searchBarIcon = "mdi-close-circle";
              this.cardHeight = 400;
              this.colLenght = 12
          } else {
              if (this.searchKeyword !== "") {
                  this.searchBarIcon = "mdi-close-circle";
              } else {
                  this.searchBarIcon = "mdi-magnify";
              }
              this.cardHeight = 650;
              this.colLenght = 9
          }
      },
      getQnaKeyword(newVal) {
          this.searchKeyword = newVal
          this.colLenght = 12
          this.searchBarIcon = "mdi-close-circle";
      },
      searchKeyword(newVal) {
          if (newVal === '') {
              this.colLenght = 9
          } else {
              this.colLenght = 12
          }
      }
  },
  methods: {
      onChange(input) {
          this.searchKeyword = input;
      },
      onKeyPress(button) {
          if (button === "{bksp}")
              this.searchKeyword = this.searchKeyword.slice(0, -1);
      },
      filteredFaqs() {
          console.log('Selected Faq Category: ', this.getSelectedFaqCat)
          return this.$i18n.t("faqs").filter(faq => faq.category.toLowerCase().includes(this.getSelectedFaqCat.name.toLowerCase()))
      },
      showMap(map) {
          let date = new Date()
          this.$i18n.t("maps").forEach(mapper => {
              if (mapper.mapName.toLowerCase().trim() === map.toLowerCase().trim()) {
                  this.$store.dispatch("openImageViewer", {
                      name: mapper.mapName,
                      url: mapper.mapImage,
                  });
                  this.$store.dispatch("avatarSpeak", mapper.speech);
                  this.tab = mapper.mapFloor
                  this.$store.dispatch('addSessionDatatoStrapi', {
                      applicationId: this.getKioskProfile.data().applicationId,
                      actionType: 'Touch',
                      applicationSessionId: this.getSessionId,
                      timeStamp: date.toISOString(),
                      sessionPayload: {
                          module: "Faqs",
                          action: "View Map",
                          response: mapper.mapName,
                          timeStamp: date.toISOString()
                      }
                  })
              }
          })
      },
      viewDoc(guide) {

          // Display the SRL Guide for reference to the user 
          // PDF viewer will give 2 options, pick up or email to self
          this.$store.dispatch('openPdfViewer', {
              url: guide.guideUrl,
              type: 'Guide',
              name: guide.guideName,
              emailLink: guide.guideLink
          })
      },
      // The below method tracks touched faqs by the user
      clickedFaq(faq) {
          const date = new Date()
          this.$store.dispatch('addSessionDatatoStrapi', {
              applicationId: this.getKioskProfile.data().applicationId,
              actionType: 'Touch',
              applicationSessionId: this.getSessionId,
              timeStamp: date.toISOString(),
              sessionPayload: {
                  module: "Frequently Asked Question",
                  action: "Show Faq",
                  response: faq.question,
                  timeStamp: date.toISOString()
              }
          })
          this.$store.commit('setSelectedFaqCat', { name: faq.category, type: 'expansionPanel' })
      }
  },
  filters: {
      // for a good UI experience.
      trimLength(val) {
          if (val.length < 28) { return val }
          return `${val.substring(0, 24)}...`
      }
  },
  mounted() {
      this.searchKeyword = this.getQnaKeyword
      if (this.searchKeyword !== '') {
          this.colLenght = 12
          this.searchBarIcon = "mdi-close-circle"
      }
  }
}
</script>
<i18n>
{
  "en": {
      "placeHolder": "Enter a keyword to search for FAQ",
      "categoryTitle": "CATEGORIES"
    },
  "es":{
      "placeHolder": "Introduzca una palabra clave para buscar preguntas frecuentes",
      "categoryTitle": "Categorías"
    }
}
</i18n>