<template>
  <v-row justify="center" align="start">
    <v-col class="pt-8" cols="12" align="center">
      <span class="text-h2 font-weight-bold grey--text text--darken-3">
        {{ $t('default') }}
      </span>
    </v-col>
    <v-col cols="10" class="py-0">
      <v-row class="fill-height mt-4 mb-4" align="center" justify="center">
        <v-col align="center" :cols="menu.col" v-for="(page, index) in $t('homepages')" :key="index" class="mb-2">
          <v-card @click="goto(page)" class="menuItem d-flex flex-column align-center justify-center"
            :width="menu.width" :height="menu.height" elevation="8">
            <v-img :src="page.menuIcon.url" :width="menu.imageWidth" contain>
            </v-img>
            <v-card-text class="text-h5 primary--text font-weight-bold">
              {{ page.menuName }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider class="pb-2 mx-10"></v-divider>
      <languageBar></languageBar>
    </v-col>
  </v-row>
</template>

<script>
import languageBar from '@/components/languageBar'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'menuMatrix',
  components: {
    languageBar
  },
  data() {
    return {
      menu: {
        height: null,
        width: null,
        col: null,
        imageWidth: null
      }
    }
  },
  computed: {
    ...mapGetters(['getCMSlink', 'getTouchCount', 'isUserLooking', 'getKioskProfile', 'getSessionId'])
  },
  methods: {
    goto(page) {
      if (page.pageType.length !== 0) {
        switch (page.pageType[0].__typename) {
          case 'ComponentHomepageNavigation':
            this.$store.commit('setRequestQna', false)
            this.$store.commit("setIsThinking", false)
            this.$store.commit("setSpeechRecognitionHandler", false);
            this.$router.push('/' + page.pageType[0].pathName.toLowerCase());
            this.$store.commit('setPageHeader', page.pageType[0].pageHeader)
            this.$store.commit('setQnaKeyword', '')

            // To start session using touch - when no face detected
            if (!this.isUserLooking) {
              this.$store.commit('setTouchCount', this.getTouchCount + 1)
              if (this.getTouchCount === 1) {
                // start time
                this.$store.commit('setStartUserSession', new Date())

                //session start object
                this.$store.dispatch('addSessionDatatoStrapi', {
                  applicationId: this.getKioskProfile.data().applicationId,
                  applicationSessionId: this.getSessionId,
                  timeStamp: moment().toISOString(),
                  actionType: 'Session Started',
                  sessionPayload: {
                    module: 'Default',
                    action: 'Touch Detected',
                    response: 'User Session Started - Touch Detected',
                  }
                })
              }
            }

            // Will be called irrespective of Touch Count
            this.$store.dispatch('addSessionDatatoStrapi', {
              applicationId: this.getKioskProfile.data().applicationId,
              applicationSessionId: this.getSessionId,
              timeStamp: moment().toISOString(),
              actionType: 'Touch',
              sessionPayload: {
                module: 'Home Page',
                action: 'navigate',
                response: page.pageType[0].pathName,
              }
            })

            this.$store.commit('setDefaultBubbleText', true)
            this.$store.dispatch('avatarSpeak', page.speech)
            break;
          case 'ComponentHomepageDialogBox':
            console.log('Open dialog box: ', page.pageType[0])
            this.$store.dispatch('avatarSpeak', "This feature is under development.")
            // if (page.pageType[0].type === 'url') {
            //   window.open(page.pageType[0].textList)
            // }
            break;
          default:
            break;
        }
      }

    },
  },
  mounted() {
    if (this.$i18n.t('homepages').length <= 4) {
      this.menu = {
        height: 260,
        width: 270,
        col: 5,
        imageWidth: "56%"
      }
    } else if (this.$i18n.t('homepages').length <= 6) {
      this.menu = {
        height: 260,
        width: 275,
        col: 4,
        imageWidth: "55%"
      }
    }
  }
}
</script>

<style>
.menuItem {
  border: solid #2c6b87 !important;
}

.footer {
  bottom: 0 !important;
}
</style>
<i18n>
    {
      "en": {
          "default": "How Can I Help You?"
        },
      "es":{
          "default": "¿Le puedo ayudar en algo?"
        }
    }
  </i18n>