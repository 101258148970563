import { ApolloClient } from "apollo-client";
import axios from "axios";
import { createHttpLink, HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import * as fb from "@/firebase/index.js";
import {
  floorQuery,
  mapQuery,
  faqQuery,
  faqCategoryQuery,
  homepageQuery,
  judgeCourtroomQuery,
} from "@/graphql/query";

function today() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  return mm + "-" + dd + "-" + yyyy;
}

export default {
  state: {
    dataLoaded: false,
    apolloClient: undefined,
    homepage: [],
    floors: [],
    maps: new Map(),
    forms: [],
    faqs: [],
    faqCategory: [],
    formCategories: [],
    formTypes: [],
    formPackets: [],
    fileCache: null,
    count: 0,
    modules: [],
    languages: [],
    kioskLocation: "",
    courts: [],
    defaultLocation: "",
    judgeLocations: [],
  },
  getters: {
    getDataLoaded: (state) => state.dataLoaded,
    getModules: (state) => state.modules,
    getHomepage: (state) => state.homepage,
    getFloors: (state) => state.floors,
    getMaps: (state) => state.maps,
    getFaqs: (state) => state.faqs,
    getFaqCategories: (state) => state.faqCategory,
    getLanguages: (state) => state.languages,
    getKioskLocation: (state) => state.kioskLocation,
    getCourts: (state) => state.courts,
    getDefaultLocation: (state) => state.defaultLocation,
    getJudgeLocations: (state) => state.judgeLocations,
  },
  mutations: {
    setDataLoaded(state, status) {
      state.dataLoaded = status;
    },
    setApolloClient(state, uri) {
      const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      });
      state.apolloClient = new ApolloClient({
        link: new HttpLink({ uri: uri }),
        cache: new InMemoryCache({ fragmentMatcher }),
      });
    },
    setModules(state, module) {
      state.modules.push(module);
    },
    setLanguages(state, lang) {
      state.languages.push(lang);
    },
    setkioskLocation(state, location) {
      state.kioskLocation = location;
    },
    setHomepage(state, page) {
      state.homepage.push(page);
    },
    setFloors(state, floor) {
      state.floors.push(floor);
    },
    setMaps(state, map) {
      state.maps.set(map.mapName.toLowerCase(), map);
    },
    setFaqs(state, faq) {
      state.faqs.push(faq);
    },
    setFaqCategory(state, category) {
      state.faqCategory.push(category);
    },
    setCourts(state, court) {
      state.courts.push(court);
    },
    setDefaultLocation(state, location) {
      state.defaultLocation = location;
    },
    setJudgeLocations(state, judgeLocation) {
      state.judgeLocations.push(judgeLocation);
    },
  },
  actions: {
    createApolloConnection({ commit }, uri) {
      return new Promise((resolve, reject) => {
        if (uri === "" || uri === null) reject("Empty or Invalid URI");
        try {
          commit("setApolloClient", uri);
          resolve("Apollo linked successfully");
        } catch (err) {
          reject(err.message);
        }
      });
    },
    initiateDataPull({ commit, dispatch }, kioskData) {
      commit("setkioskLocation", kioskData.data().locationCode);
      dispatch("indentifyModuleLang", {
        module: kioskData.data().modules,
        language: kioskData.data().languages,
      });
      commit("setDefaultLocation", kioskData.data().defaultLocation);
      dispatch("identifyAvatarLangCodes", "en");
      //dispatch('updateAutoReferesh', kioskData)
    },
    indentifyModuleLang({ commit, dispatch }, data) {
      data.module.forEach((mod) => {
        commit("setModules", mod);
      });
      data.language.forEach((lang) => {
        commit("setLanguages", lang);
      });
      dispatch("loadAvatar");
    },
    fetchHomePage({ commit, dispatch, state, getters }) {
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: homepageQuery,
              variables: {
                alias: getters.getModules,
                lang: lang,
              },
            })
            .then(
              (items) => {
                items.data.homepages.forEach((page) => {
                  dispatch("saveInCache", page.menuIcon.url).then(
                    (response) => {
                      page.menuIcon.url = response;
                      commit("setHomepage", page);
                    }
                  );
                });
                resolve("Homepage fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },
    // Fetch Floor from strapi
    fetchFloors({ commit, dispatch, state, getters }) {
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: floorQuery,
              variables: {
                location: getters.getKioskLocation,
                lang: lang,
              },
            })
            .then(
              (floors) => {
                floors.data.floors.forEach((floor) => {
                  dispatch("saveInCache", floor.floorImage.url).then(
                    (response) => {
                      floor.floorImage.url = response;
                      commit("setFloors", floor);
                    }
                  );
                });
                resolve("Floor data fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    // Fetching Maps from Strapi
    fetchMaps({ commit, state, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: mapQuery,
              variables: {
                location: getters.getKioskLocation,
                lang: lang,
              },
            })
            .then(
              (maps) => {
                maps.data.maps.forEach((map) => {
                  dispatch("saveInCache", map.mapImage.url).then((response) => {
                    commit("setMaps", {
                      mapName: map.mapName,
                      mapFloor: map.mapFloor,
                      mapImage: response,
                      speech: map.speech,
                      locale: map.locale,
                      displayPosition: map.displayPosition,
                      courtroomLocationCode: map.courthouseLocationCode,
                    });
                  });
                });
                resolve("Map data fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    // --------------------------------------------------------- Fetch Data related to Faqs Page -----------------------------------------

    fetchFaqs({ commit, state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: faqQuery,
              variables: {
                lang: lang,
              },
            })
            .then(
              (faqs) => {
                //commit('setFaqs', faqs.data.faqs);
                faqs.data.faqs.forEach((faq) => {
                  dispatch("fetchFaqCategory", faq.category);
                  let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, "<br />"),
                    category: faq.category,
                    displayType: "panel",
                    locale: faq.locale,
                    showMap: faq.showMap,
                    mapName: faq.mapName,
                    showQrCode: faq.showQr,
                    qrInfo: faq.qrDetails,
                    linkId: faq.linkID,
                  };
                  commit("setFaqs", data);
                });
                resolve("FAQs fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    fetchFaqCategory({ commit, state, getters }, faqCategory1) {
      return new Promise((response, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: faqCategoryQuery,
              variables: {
                lang: lang,
                category: faqCategory1,
              },
            })
            .then(
              (faqCategory) => {
                faqCategory.data.faqCategories.forEach((category) => {
                  if (getters.getFaqCategories.length === 0) {
                    commit("setFaqCategory", category);
                  } else {
                    let temp = getters.getFaqCategories.filter(
                      (faqCat) => faqCat.displayOrder === category.displayOrder
                    );

                    if (temp.length === 0) {
                      commit("setFaqCategory", category);
                    }
                  }
                });

                response("FAQ categories fetched successfully");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    // --------------------------------------------------------- Fetch Data related to Judge Locations Page -----------------------------------------

    fetchJudgeLocations({ commit, state, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        state.apolloClient
          .query({
            query: judgeCourtroomQuery,
          })
          .then(
            (judgeCourtroom) => {
              judgeCourtroom.data.judgeLocations.forEach((location) => {
                commit("setJudgeLocations", location);
              });
              resolve("Judge Locations data fetched successfully.");
            },
            (error) => {
              reject(error.message);
            }
          );
      });
    },

    // --------------------------------- Data Caching (Image, PDF) ------------------------------------------------------------
    saveInCache({ state, getters }, path) {
      return new Promise((response, reject) => {
        state.fileCache = caches.open("fileCache").then((cache) => {
          cache.match(getters.getCMSlink + path).then((cacheResponse) => {
            if (cacheResponse) {
              return cacheResponse.blob().then((blob) => {
                response(URL.createObjectURL(blob));
              });
            } else {
              cache.add(getters.getCMSlink + path);
              cache.match(getters.getCMSlink + path).then((cacheResponse) => {
                return cacheResponse.blob().then((blob) => {
                  response(URL.createObjectURL(blob));
                });
              });
            }
          });
        });
      });
    },

    // ---------------------------------------------- Odessey --------------------------------------------------------------

    addToLocalStorage({ getters }) {
      localStorage.setItem("floors", getters.getFloors);
      localStorage.setItem("maps", getters.getMaps);
      localStorage.setItem("faqs", getters.getFaqs);
      localStorage.setItem("formcategory", getters.getFormCategories);
      localStorage.setItem("forms", getters.getForms);
      localStorage.setItem("homepages", getters.getHomepage);
    },
  },
};
