import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { auth } from "./firebase/index";
import { mapGetters } from "vuex";
import i18n from "./i18n";
Vue.config.productionTip = false;

let app;

auth.onAuthStateChanged((kiosk) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,

      render: function (h) {
        return h(App, { props: {} });
      },

      data() {
        return {
          avatar: undefined,
        };
      },

      computed: {
        ...mapGetters(["getAvatarLoaded"]),
      },

      watch: {
        $route(to, from) {
          if (to.path !== "/login" && from.path !== "/login") {
            this.$nextTick(() => {
              const newAvatarComp = this.findAvatarComponent(this);
              const newWidth = newAvatarComp.width - 1;
              if (newAvatarComp) {
                this.$nextTick(() => {
                  newAvatarComp.$el.replaceChild(
                    this.avatar.$el.children[0],
                    newAvatarComp.$el.children[0]
                  );
                  this.avatar = newAvatarComp;
                  this.avatar.width = newWidth;
                });
              }
            });
          }
        },
        getAvatarLoaded(newVal) {
          if (newVal) {
            this.avatar = this.findAvatarComponent(this);
          }
        },
      },

      methods: {
        findAvatarComponent: function (parent) {
          let found = parent.$children.find((child) => {
            return child.$options.name === "avatar";
          });
          for (let i = 0; !found && i < parent.$children.length; i++) {
            found = this.findAvatarComponent(parent.$children[i]);
          }
          return found;
        },
      },

      mounted() {
        //this.avatar = this.findAvatarComponent(this);
      },

      i18n,

      beforeMount() {
        store.commit("setAppDimensions");
        //window["console"]["log"] = function () {};
        console.warn = function () {};
        //console.error = function () {};
      },
    }).$mount("#app");
  }
  if (kiosk) {
    store.dispatch("fetchKioskProfile", kiosk);
    store.dispatch("manageKioskPresence", kiosk);
    setInterval(() => {
      store.dispatch("manageKioskPresence", kiosk);
    }, 3600000);
  }
});
